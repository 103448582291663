import JsonSchema, { JsonSchemaObject } from '@evidentid/json-schema/interfaces/JsonSchema';
import { JsonFormProperty } from '@evidentid/json-schema/interfaces/JsonForm';
import { EntityStandardPropertyName } from '@/modules/entity-management/models/EntityStandardPropertyName.model';

function getObjectPropKey(schema: JsonSchema): string[] {
    return ('propertiesOrder' in schema &&
        schema.propertiesOrder &&
        schema.propertiesOrder.length > 0)
        ? schema.propertiesOrder
        : Object.keys((schema as JsonSchemaObject).properties);
}

export function getFlattenedStandardPropertyKeys(properties: JsonFormProperty[]): string[] {
    const keys = [] as string[];
    properties.forEach((property) => {
        if (property.name === EntityStandardPropertyName.address) {
            // TODO[PRODUCT-23623]: band-aid for address conflict, needs support generic object type standard prop
            const addressKeys = getObjectPropKey(property.form.schema);
            keys.push(...addressKeys.map((x) => `entityAddress.${x}`));
        } else {
            keys.push(property.name);
        }
    });
    return keys;
}
