import { getAddressPropOrder } from '@/modules/entity-management/utils/get-address-props-order/getAddressPropOrder';

function prefixStandardFieldObjectTypeCsvKeys(headerKeys: string[], objectKey: string): string[] {
    return headerKeys.map((key) => {
        if (getAddressPropOrder().includes(key)) {
            return `${objectKey}.${key}`;
        }
        return key;
    });
}

export function prefixStandardFieldAddressCsvKeys(headerKeys: string[]): string[] {
    // TODO[PRODUCT-23623]: band-aid for address conflict, needs support generic object type standard prop
    // return prefixStandardFieldObjectTypeCsvKeys(headerKeys, EntityStandardPropertyName.address);
    return prefixStandardFieldObjectTypeCsvKeys(headerKeys, 'entityAddress');
}

export function appendStandardFieldAddressToObjectTypeKeysList(objectKeys: string[]): string[] {
    // TODO[PRODUCT-23623]: band-aid for address conflict, needs support generic object type standard prop
    // return [ EntityStandardPropertyName.address, ...objectKeys ];
    return [ 'entityAddress', ...objectKeys ];
}
